import React, {FC} from 'react';
import {Link} from "react-router-dom";
import logo from '../assets/img/hippo/logo.png'
import classNames from "classnames";
import {useAppSelector} from "../app/store/hooks/hooks";
import {selectLang} from "../app/store/reducers/main/main";
import {Translate} from "../lang/lang";
import navbarTop from "./navbar/top/NavbarTop";


type LogoType = {
    width?: number,
    at?: 'navbar-vertical' | 'navbar-top' | 'auth',
    className?: string,
    textClass?: string,
    to: string,
    navbarTop?: boolean
}

const Logo: FC<LogoType> = ({width = 58, at = 'auto', className, textClass, to, navbarTop}) => {

    const lang = useAppSelector(selectLang)
    const t = Translate

    return (
        <Link to={to} className={classNames(
            'text-decoration-none d-none d-lg-inline',
            {'navbar-brand text-left': at === 'navbar-vertical'},
            {'navbar-brand text-left': at === 'navbar-top'}
        )}>
            <div className={`d-flex justify-content-center align-items-center text-center ${className}`}>
                <img src={logo} alt="Logo" width={width} height={40} className={!navbarTop ? 'me-2': ''}/>
                {
                    navbarTop &&
                    <span className={classNames('ms-2 font-sans-serif', textClass)}>
                        Hippo
                        <span className={'d-block'}>{t(lang, 'logo.text')}</span>
                    </span>
                }
                {
                    !navbarTop &&
                    <span className={classNames('font-sans-serif', textClass)}>
                        Hippo - {t(lang, 'logo.text')} staging
                    </span>
                }
            </div>
        </Link>
    );
};

export default Logo;
